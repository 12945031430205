



















































import "vue-swatches/dist/vue-swatches.css";
import { ImageEditor } from "@toast-ui/vue-image-editor";
import { Component, Vue, Watch } from "vue-property-decorator";
import VSwatches from "vue-swatches";
import { BButton, BFormFile, BCard, BFormInput, BFormSelect, BRow, BCol } from "bootstrap-vue";

import mediaAppModel from "@/api/media.app.model";
import { showErrorAlert } from "@/helpers";

@Component({ components: { ImageEditor, BFormFile, BButton, BCard, BFormInput, BFormSelect, BRow, BCol, VSwatches } })
export default class BlogPostTemplateEditor extends Vue {
	media = null;
	imageEditor: any;
	file: any = null;

	fontOptions = [
		{ value: "Helvetica", text: "Helvetica" },
		{ value: "Arial", text: "Arial" },
		{ value: "Times New Roman", text: "Times New Roman" },
		{ value: "Playfair Display", text: "Playfair Display" },
		{ value: "Source Sans Pro", text: "Source Sans Pro" },
		{ value: "Aileron Regular", text: "Aileron Regular" },
		{ value: "Nickainley", text: "Nickainley" },
		{ value: "Vidaloka", text: "Vidaloka" },
		{ value: "Peace Sans", text: "Peace Sans" },
		{ value: "League Gothic", text: "League Gothic" },
		{ value: "Quicksand", text: "Quicksand" },
		{ value: "Geometric Sans Serif", text: "Geometric Sans Serif" },
		{ value: "Sailors", text: "Sailors" },
	];

	text = "";
	textColor = "#000000";
	textFace = "Helvetica";

	options = {
		selectionStyle: {
			cornerSize: 25,
			cornerStrokeColor: "#101010",
			cornerColor: "#eeeeee",
			borderColor: "#101010",
		},
		includeUI: false,
		cssMaxWidth: 700,
		cssMaxHeight: 500,
	};

	activeObjectId: number | null = null;

	mounted() {
		this.imageEditor = <any>this.$refs.imageEditor;
		this.loadMedia();
	}

	async loadMedia() {
		this.$store.dispatch("app/showLoading");

		const media = await mediaAppModel.get(this.$route.params.mediaId);

		await this.imageEditor.invoke("loadImageFromURL", media.fileUrl, "My sample image");

		this.$store.dispatch("app/hideLoading");
	}

	@Watch("file")
	async fileChanged() {
		if (!this.file) {
			return;
		}

		const fileImage: HTMLImageElement = await new Promise(resolve => {
			const image = new Image();
			image.onload = () => resolve(image);
			image.src = URL.createObjectURL(this.file);
		});

		const imageEditorSize = await this.imageEditor.invoke("getCanvasSize");

		const resizingCanvas = document.createElement("canvas");
		const resizingFactor = Math.min(
			imageEditorSize.height / fileImage.height,
			imageEditorSize.width / fileImage.width,
			1,
		);
		resizingCanvas.width = fileImage.width * resizingFactor;
		resizingCanvas.height = fileImage.height * resizingFactor;
		resizingCanvas.getContext("2d")?.drawImage(fileImage, 0, 0, resizingCanvas.width, resizingCanvas.height);

		const resizedImageDataURL = resizingCanvas.toDataURL("image/png");

		await this.imageEditor.invoke("addImageObject", resizedImageDataURL);

		this.file = null;
	}

	async addText() {
		if (!this.text) {
			showErrorAlert("Digite um texto para inserir");
			return;
		}

		await this.imageEditor.invoke("discardSelection"); // previne bug de texto não desselecionável

		await this.imageEditor.invoke("addText", this.text, {
			styles: {
				fill: this.textColor,
				fontFamily: this.textFace,
				fontSize: 100,
			},
		});

		await this.imageEditor.invoke("discardSelection");
	}

	saveImage() {
		const link = document.createElement("a");
		link.download = "publicacao.png";
		link.href = this.imageEditor.invoke("toDataURL");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	onObjectActivated(object: any) {
		this.activeObjectId = object.id;
	}

	onSelectionCleared() {
		this.activeObjectId = null;
	}

	@Watch("textFace")
	async textFaceChanged() {
		if (this.activeObjectId && this.textFace) {
			await this.imageEditor.invoke("changeTextStyle", this.activeObjectId, { fontFamily: this.textFace });
			await this.imageEditor.invoke("discardSelection");
		}
	}

	deselectAll() {
		this.imageEditor.invoke("discardSelection");
	}
}
